import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Products from "./pages/Products";
import SingleProduct from "./pages/SingleProduct";
import ScrollToTop from "./components/ScrollToTop";
import Login from "./pages/Login";
import Register from "./pages/Register";
import LgCart from "./pages/LgCart";
import Checkout from "./pages/Checkout";
import UserDashboard from "./pages/UserDashboard";
import Blog from "./pages/Blog";
import Magazine from "./pages/Magazine";
import PhoneNumbers from "./pages/PhoneNumbers";
import Saparu from "./pages/Saparu";
import Viki from "./pages/Viki";
import Viki1 from "./pages/Viki1";
import Viki2 from "./pages/Viki2";
import Viki3 from "./pages/Viki3";

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/products" element={<Products />} />
        <Route exact path="/item" element={<SingleProduct />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/cart" element={<LgCart />} />
        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/my-lg" element={<UserDashboard />} />
        <Route exact path="/blogs" element={<Blog />} />
        <Route exact path="/magazine" element={<Magazine />} />
        <Route exact path="/phonenumbers" element={<PhoneNumbers />} />
        <Route exact path="/saparu" element={<Saparu />} />
        <Route exact path="/viki" element={<Viki />} />
        <Route exact path="/viki1" element={<Viki1 />} />
        <Route exact path="/viki2" element={<Viki2 />} />
        <Route exact path="/viki3" element={<Viki3 />} />
      </Routes>
      <ScrollToTop />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
