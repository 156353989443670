import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import gsap from "gsap";
import * as dat from "dat.gui";
import { color, texture, textureLoad } from "three/tsl";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { DirectionalLight } from "three/webgpu";

// import imageSource from "./color.jpg";

const Viki2 = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!canvasRef.current) return;

    const gui = new dat.GUI();

    //Textures
    const textureLoader = new THREE.TextureLoader();
    const bakedShadow = textureLoader.load("./bakedShadow.jpg");
    const simpleShadow = textureLoader.load("./simpleShadow.jpg");

    const scene = new THREE.Scene();

    /**
     * Lights
     */
    // Ambient light
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    gui.add(ambientLight, "intensity").min(0).max(3).step(0.001);
    scene.add(ambientLight);

    // Directional light
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(2, 2, -1);
    gui.add(directionalLight, "intensity").min(0).max(3).step(0.001);
    gui.add(directionalLight.position, "x").min(-5).max(5).step(0.001);
    gui.add(directionalLight.position, "y").min(-5).max(5).step(0.001);
    gui.add(directionalLight.position, "z").min(-5).max(5).step(0.001);

    directionalLight.castShadow = true;

    directionalLight.shadow.mapSize.width = 1024;
    directionalLight.shadow.mapSize.height = 1024;

    directionalLight.shadow.camera.top = 2;
    directionalLight.shadow.camera.right = 2;
    directionalLight.shadow.camera.bottom = -2;
    directionalLight.shadow.camera.left = -2;
    directionalLight.shadow.camera.near = 1;
    directionalLight.shadow.camera.far = 6;

    // directionalLight.shadow.radius = 10;

    const directionalLightCameraHelper = new THREE.CameraHelper(
      directionalLight.shadow.camera
    );
    scene.add(directionalLightCameraHelper);
    directionalLightCameraHelper.visible = false;

    scene.add(directionalLight);

    //Spot Light
    const spotLight = new THREE.SpotLight(0xffffff, 4, 10, Math.PI * 0.3);
    spotLight.castShadow = true;

    spotLight.shadow.mapSize.width = 1024;
    spotLight.shadow.mapSize.height = 1024;
    spotLight.shadow.camera.fov = 30;
    spotLight.shadow.camera.near = 1;
    spotLight.shadow.camera.far = 6;

    spotLight.position.set(0, 2, 2);
    scene.add(spotLight);
    scene.add(spotLight.target);

    const spotLightCameraHelper = new THREE.CameraHelper(
      spotLight.shadow.camera
    );
    spotLightCameraHelper.visible = false;
    scene.add(spotLightCameraHelper);

    //Point Light
    const pointLight = new THREE.PointLight(0xffffff, 1.5);
    pointLight.castShadow = true;

    pointLight.shadow.mapSize.width = 1024;
    pointLight.shadow.mapSize.height = 1024;

    pointLight.shadow.camera.near = 0.1;
    pointLight.shadow.camera.far = 5;

    pointLight.position.set(-1, 1, 0);
    scene.add(pointLight);

    const pointLightCameraHelper = new THREE.CameraHelper(
      pointLight.shadow.camera
    );
    pointLightCameraHelper.visible = false;
    scene.add(pointLightCameraHelper);

    /**
     * Materials
     */
    const material = new THREE.MeshStandardMaterial();
    material.roughness = 0.7;
    gui.add(material, "metalness").min(0).max(1).step(0.001);
    gui.add(material, "roughness").min(0).max(1).step(0.001);

    /**
     * Objects
     */
    const sphere = new THREE.Mesh(
      new THREE.SphereGeometry(0.5, 32, 32),
      material
    );

    sphere.castShadow = true;

    const plane = new THREE.Mesh(new THREE.PlaneGeometry(5, 5), material);
    plane.rotation.x = -Math.PI * 0.5;
    plane.position.y = -0.5;

    plane.receiveShadow = true;

    scene.add(sphere, plane);

    const sphereShadow = new THREE.Mesh(
      new THREE.PlaneGeometry(1.5, 1.5),
      new THREE.MeshBasicMaterial({
        color: 0x000000,
        transparent: true,
        alphaMap: simpleShadow,
      })
    );

    sphereShadow.rotation.x = -Math.PI * 0.5;
    sphereShadow.position.y = plane.position.y + 0.01;
    scene.add(sphereShadow);

    // Initialize the sizes object before anything else
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    // Cursor movement tracking
    const cursor = {
      x: 0,
      y: 0,
    };

    // Setup mousemove event listener
    window.addEventListener("mousemove", (event) => {
      cursor.x = event.clientX / sizes.width - 0.5;
      cursor.y = -(event.clientY / sizes.height - 0.5);
    });

    // Camera setup
    const camera = new THREE.PerspectiveCamera(
      75,
      sizes.width / sizes.height,
      0.1,
      100
    );
    camera.position.z = 3;
    scene.add(camera);

    // Orbit controls setup
    const controls = new OrbitControls(camera, canvasRef.current);
    controls.enableDamping = true;

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    renderer.shadowMap.enabled = false;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    // Resize event listener
    const handleResize = () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;

      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Fullscreen toggle handling
    window.addEventListener("dblclick", () => {
      const fullscreenElement =
        document.fullscreenElement || document.webkitFullscreenElement;

      if (!fullscreenElement) {
        if (canvasRef.current.requestFullscreen) {
          canvasRef.current.requestFullscreen();
        } else if (canvasRef.current.webkitRequestFullscreen) {
          canvasRef.current.webkitRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    });

    // Animation loop
    const clock = new THREE.Clock();
    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      //update the sphere
      sphere.position.x = Math.cos(elapsedTime) * 1.5;
      sphere.position.z = Math.sin(elapsedTime) * 1.5;
      sphere.position.y = Math.abs(Math.sin(elapsedTime * 3));

      //update the shadow
      sphereShadow.position.x = sphere.position.x;
      sphereShadow.position.z = sphere.position.z;
      sphereShadow.material.opacity = (1 - sphere.position.y) * 0.3;

      controls.update();
      renderer.render(scene, camera);

      window.requestAnimationFrame(tick);
    };

    tick();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("mousemove", () => {});
    };
  }, []);
  return (
    <>
      <style>
        {`
          * {
            margin: 0;
            padding: 0;
          }

          html, body {
            overflow: hidden;
          }

          header, .need-help, .main_footer, .bottom_footer,  .mob-header {
            display: none !important;
          }

          .webgl {
            position: fixed;
            top: 0;
            left: 0;
            outline: none;
          }
        `}
      </style>
      <canvas ref={canvasRef} className="vikimaharjan"></canvas>
    </>
  );
};

export default Viki2;
