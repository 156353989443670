import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import gsap from "gsap";
import * as dat from "dat.gui";
import { color, texture, textureLoad } from "three/tsl";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";

// import imageSource from "./color.jpg";

const Viki = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!canvasRef.current) return;

    // const gui = new dat.GUI();

    //Textures
    const textureLoader = new THREE.TextureLoader();
    // const CubeTextureLoader = new THREE.CubeTextureLoader();
    // const doorColorTexture = textureLoader.load("./color.jpg");
    // const doorAlphaTexture = textureLoader.load("./alpha.jpg");
    // const doorAmbientTexture = textureLoader.load("./ambient.jpg");
    // const doorHeightTexture = textureLoader.load("./height.jpg");
    // const doorNormalTexture = textureLoader.load("./normal.jpg");
    // const doorMetalnessTexture = textureLoader.load("./metalness.jpg");
    // const doorRoughnessTexture = textureLoader.load("./roughness.jpg");
    const matcapTexture = textureLoader.load("./matcaps/1.png");

    // const gradientTexture = textureLoader.load("./gradients/5.jpg");
    // gradientTexture.minFilter = THREE.NearestFilter;
    // gradientTexture.magFilter = THREE.NearestFilter;

    // const environmentMapTexture = CubeTextureLoader.load([
    //   "./environmentMap/px.png",
    //   "./environmentMap/nx.png",
    //   "./environmentMap/py.png",
    //   "./environmentMap/ny.png",
    //   "./environmentMap/pz.png",
    //   "./environmentMap/nz.png",
    // ]);

    // colorTexture.repeat.x = 2;
    // colorTexture.repeat.y = 3;
    // colorTexture.wrapS = THREE.MirroredRepeatWrapping;
    // colorTexture.wrapT = THREE.MirroredRepeatWrapping;
    // colorTexture.offset.x = 0.5;
    // colorTexture.offset.y = 0.5;
    // colorTexture.rotation = Math.PI * 0.25;
    // colorTexture.center.x = 0.5;
    // colorTexture.center.y = 0.5;

    // colorTexture.generateMipmaps = false;
    // colorTexture.minFilter = THREE.NearestFilter;
    // colorTexture.magFilter = THREE.NearestFilter;

    //Textures Ends

    // Initialize the sizes object before anything else
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    // Cursor movement tracking
    const cursor = {
      x: 0,
      y: 0,
    };

    // Setup mousemove event listener
    window.addEventListener("mousemove", (event) => {
      cursor.x = event.clientX / sizes.width - 0.5;
      cursor.y = -(event.clientY / sizes.height - 0.5);
    });

    // Setup the scene
    const scene = new THREE.Scene();

    // const material = new THREE.MeshBasicMaterial();
    // material.map = doorColorTexture;
    // material.wireframe = true;
    // material.opacity = 0.5;
    // material.transparent = true;
    // material.alphaMap = doorAlphaTexture;

    // const material = new THREE.MeshNormalMaterial();
    // material.wireframe = true;
    // material.flatShading = true;

    // const material = new THREE.MeshMatcapMaterial();
    // material.matcap = matcapTexture;

    // const material = new THREE.MeshDepthMaterial();

    // const material = new THREE.MeshLambertMaterial();

    // const material = new THREE.MeshPhongMaterial({ color: "white" });
    // material.shininess = 1000;
    // material.specular = new THREE.Color("blue");

    // const material = new THREE.MeshToonMaterial();
    // material.gradientMap = gradientTexture;

    // const material = new THREE.MeshStandardMaterial();
    // material.metalness = 0;
    // material.roughness = 1;
    // material.map = doorColorTexture;
    // material.aoMap = doorAmbientTexture;
    // material.aoMapIntensity = 1;
    // material.displacementMap = doorHeightTexture;
    // material.displacementScale = 0.05;
    // material.metalnessMap = doorMetalnessTexture;
    // material.roughnessMap = doorRoughnessTexture;
    // material.normalMap = doorNormalTexture;
    // material.transparent = true;
    // material.alphaMap = doorAlphaTexture;
    // material.normalScale.set(0.5, 0.5);

    // const material = new THREE.MeshStandardMaterial();
    // material.metalness = 0.7;
    // material.roughness = 0.2;
    // material.envMap = environmentMapTexture;

    // material.side = THREE.DoubleSide;

    // gui.add(material, "metalness").min(0).max(1).step(0.0001);
    // gui.add(material, "roughness").min(0).max(1).step(0.0001);
    // gui.add(material, "aoMapIntensity").min(0).max(10).step(0.0001);
    // gui.add(material, "displacementScale").min(0).max(1).step(0.0001);

    // const sphere = new THREE.Mesh(
    //   new THREE.SphereGeometry(0.5, 64, 64),
    //   material
    // );

    // sphere.geometry.setAttribute(
    //   "uv2",
    //   new THREE.BufferAttribute(sphere.geometry.attributes.uv.array, 2)
    // );

    // sphere.position.x = -1.5;

    // const plane = new THREE.Mesh(
    //   new THREE.PlaneGeometry(1, 1, 100, 100),
    //   material
    // );

    // plane.geometry.setAttribute(
    //   "uv2",
    //   new THREE.BufferAttribute(plane.geometry.attributes.uv.array, 2)
    // );

    // const torus = new THREE.Mesh(
    //   new THREE.TorusGeometry(0.3, 0.2, 64, 128),
    //   material
    // );

    // torus.geometry.setAttribute(
    //   "uv2",
    //   new THREE.BufferAttribute(torus.geometry.attributes.uv.array, 2)
    // );

    // torus.position.x = 1.5;

    // scene.add(sphere, plane, torus);
    // const cube = new THREE.Mesh(
    //   new THREE.BoxGeometry(1, 1, 1),
    //   new THREE.MeshBasicMaterial()
    // );

    // const axesHelper = new THREE.AxesHelper();
    // scene.add(axesHelper);

    //FONTS
    const fontLoader = new FontLoader();

    fontLoader.load("/fonts/helvetiker_regular.typeface.json", (font) => {
      const textGeometry = new TextGeometry("Vicky Maharjan", {
        font: font,
        size: 0.3,
        depth: 0.2,
        curverSegments: 5,
        bevelEnabled: true,
        bevelThickness: 0.03,
        bevelSize: 0.02,
        bevelOffset: 0,
        bevelSegments: 4,
      });

      textGeometry.center();
      const material = new THREE.MeshMatcapMaterial({
        matcap: matcapTexture,
      });
      const text = new THREE.Mesh(textGeometry, material);
      scene.add(text);

      const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45);
      for (let i = 0; i < 200; i++) {
        const donut = new THREE.Mesh(donutGeometry, material);

        donut.position.x = (Math.random() - 0.5) * 10;
        donut.position.y = (Math.random() - 0.5) * 10;
        donut.position.z = (Math.random() - 0.5) * 10;

        donut.rotation.x = Math.random() * Math.PI;
        donut.rotation.y = Math.random() * Math.PI;

        const scale = Math.random();
        donut.scale.set(scale, scale, scale);

        scene.add(donut);
      }
    });

    // scene.add(cube);

    //lights
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);
    const pointLight = new THREE.PointLight(0xffffff, 1);
    pointLight.position.set(2, 3, 4);
    scene.add(pointLight);

    // const gui = new dat.GUI({ closed: true, width: 400 });
    // gui.hide();
    // const parameters = {
    //   color: "#ff0000",
    //   spin: () => {
    //     gsap.to(mesh.rotation, {
    //       duration: 1,
    //       y: mesh.rotation.y + 10,
    //     });
    //   },
    // };

    // gui.addColor(parameters, "color").onChange(() => {
    //   material.color.set(parameters.color);
    // });

    // gui.add(parameters, "spin");
    // Create geometry and material
    // const geometry = new THREE.BoxGeometry(1, 1, 1, 4, 4, 4);
    // const geometry = new THREE.BufferGeometry();

    // const count = 5000;
    // const positionsArray = new Float32Array(count * 3 * 3);

    // for (let i = 0; i < count * 3 * 3; i++) {
    //   positionsArray[i] = (Math.random() - 0.5) * 4;
    // }

    // const positionsAttribute = new THREE.BufferAttribute(positionsArray, 3);
    // geometry.setAttribute("position", positionsAttribute);

    // const material = new THREE.MeshBasicMaterial({
    //   color: parameters.color,
    // });
    // const material = new THREE.MeshBasicMaterial({
    //   map: colorTexture,
    // });
    // const mesh = new THREE.Mesh(geometry, material);
    // scene.add(mesh);

    //Debug

    // gui.add(mesh.position, "y").min(-3).max(3).step(0.01).name("elevation");
    // gui.add(mesh, "visible");
    // gui.add(material, "wireframe");
    //Debug ends

    // Camera setup
    const camera = new THREE.PerspectiveCamera(
      75,
      sizes.width / sizes.height,
      0.1,
      100
    );
    camera.position.z = 3;
    scene.add(camera);

    // Orbit controls setup
    const controls = new OrbitControls(camera, canvasRef.current);
    controls.enableDamping = true;

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // Resize event listener
    const handleResize = () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;

      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Fullscreen toggle handling
    window.addEventListener("dblclick", () => {
      const fullscreenElement =
        document.fullscreenElement || document.webkitFullscreenElement;

      if (!fullscreenElement) {
        if (canvasRef.current.requestFullscreen) {
          canvasRef.current.requestFullscreen();
        } else if (canvasRef.current.webkitRequestFullscreen) {
          canvasRef.current.webkitRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    });

    // Animation loop
    const clock = new THREE.Clock();
    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      //update objects
      // sphere.rotation.y = 0.1 * elapsedTime;
      // plane.rotation.y = 0.1 * elapsedTime;
      // torus.rotation.y = 0.1 * elapsedTime;

      // sphere.rotation.x = 0.15 * elapsedTime;
      // plane.rotation.x = 0.15 * elapsedTime;
      // torus.rotation.x = 0.15 * elapsedTime;

      controls.update();
      renderer.render(scene, camera);

      window.requestAnimationFrame(tick);
    };

    tick();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("mousemove", () => {});
    };
  }, []);
  return (
    <>
      <style>
        {`
          * {
            margin: 0;
            padding: 0;
          }

          html, body {
            overflow: hidden;
          }

          header, .need-help, .main_footer, .bottom_footer,  .mob-header {
            display: none !important;
          }

          .webgl {
            position: fixed;
            top: 0;
            left: 0;
            outline: none;
          }
        `}
      </style>
      <canvas ref={canvasRef} className="vikimaharjan"></canvas>
    </>
  );
};

export default Viki;
